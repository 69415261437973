import http from '@/libs/http'
import { useStore } from '@/store'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class ReportesService {
  reportePendientes (params) {
    const store = useStore()
    const token = store.getters['auth/getToken']
    params.token = `Bearer ${token}`
    return http.getUri({ url: `${baseUrl}/reportes/reporte-pendientes`, params })
  }

  reporteStock (params) {
    const store = useStore()
    const token = store.getters['auth/getToken']
    params.token = `Bearer ${token}`
    return http.getUri({ url: `${baseUrl}/reportes/reporte-stock`, params })
  }
}
