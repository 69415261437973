<template>
  <Dialog
    v-model:visible="visible"
    modal
    header="Reporte de Pendientes"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <div class="flex flex-col gap-2">
      <span class="font-bold">Filtros:</span>
      <div class="flex flex-wrap md:flex-nowrap gap-2">
        <section class="flex flex-col justify-center w-full md:w-48">
          <label>Cliente</label>
          <Dropdown
            v-model="filtros.CardCode"
            :options="clientes"
            filter
            showClear
            optionLabel="CardName"
            optionValue="CardCode"
            class="w-full"
          />
        </section>
        <section class="flex flex-col justify-center w-full">
          <label>Fecha Inicio</label>
          <input
            type="date"
            v-model="filtros.fechaInicio"
            class="w-full p-inputtext"
          />
        </section>
        <section class="flex flex-col justify-center w-full">
          <label>Fecha Fin</label>
          <input
            type="date"
            v-model="filtros.fechaFin"
            class="w-full p-inputtext"
          />
        </section>
        <section class="flex flex-col justify-center w-full">
          <label>Modalidad de contrato</label>
          <Dropdown
            id="ModContrato"
            v-model="filtros.U_PHR_ModContrato"
            :options="modalidadesContrato"
            optionLabel="descripcion"
            optionValue="value"
            class="w-full md:w-48"
            @change="changeModContrato"
            showClear
          />
        </section>
        <section class="flex flex-col justify-center w-full" v-show="visibleMotAutoriza">
          <label>Motivo de autorización</label>
          <Dropdown
            id="MotAutoriza"
            v-model="filtros.U_PHR_MotAutoriza"
            :options="motivosAutorizacion"
            filter
            showClear
            optionLabel="Nombre"
            optionValue="Codigo"
            class="w-full md:w-48"
          />
        </section>
      </div>
      <div class="flex justify-end">
        <Button
          id="GenerarReporte"
          label="Generar Reporte"
          icon="pi pi-search"
          class="p-button-raised p-button-rounded p-button-success"
          @click="clickGenerarReporte"
        />
      </div>
    </div>
  </Dialog>
</template>
<script setup>
import { ref, defineExpose } from 'vue'
import ClientesService from '../../services/clientes.service'
import ModalidadContratoService from '../../services/modalidad-contrato.service'
import MotivosAutorizacionService from '../../services/motivos_autorizacion.service'
import ReportesService from '../../services/reportes.service'

// Services
const clientesService = new ClientesService()
const modalidadContratoService = new ModalidadContratoService()
const motivosAutorizacionService = new MotivosAutorizacionService()
const reportesService = new ReportesService()

// Refs
const visible = ref(false)
const visibleMotAutoriza = ref(false)
const filtros = ref({
  // Rango de fechas createdAt
  fechaInicio: null,
  fechaFin: null,
  CardCode: null,
  U_PHR_ModContrato: null,
  U_PHR_MotAutoriza: null
})
const clientes = ref([])
const modalidadesContrato = ref([])
const motivosAutorizacion = ref([])

// Methods
const actionModal = () => {
  clientesService.search().then((res) => {
    clientes.value = res.data
  })
  modalidadContratoService.search().then((res) => {
    modalidadesContrato.value = res.data
  })
  visible.value = !visible.value
}
const changeModContrato = () => {
  filtros.value.U_PHR_MotAutoriza = null
  filtros.value.U_PHR_ModContrato ? visibleMotAutoriza.value = true : visibleMotAutoriza.value = false
  motivosAutorizacionService.listarMotivos({ CodModContrato: filtros.value.U_PHR_ModContrato }).then((res) => {
    motivosAutorizacion.value = res.data
  })
}
const clickGenerarReporte = () => {
  const url = reportesService.reportePendientes(filtros.value)
  window.open(url, '_blank')
}

// Expose methods
defineExpose({
  actionModal
})
</script>
