<template>
  <Dialog
    v-model:visible="visible"
    modal
    header="Reporte de Stock"
    :style="{ width: '20vw' }"
    :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
  >
    <div class="flex flex-col gap-2">
      <div class="flex flex-wrap md:flex-nowrap gap-2 justify-start">
        <section class="flex flex-col justify-center w-full">
          <label>Bodega</label>
          <Dropdown
            id="Bodega"
            v-model="filtros.CodBod"
            :options="bodegas"
            optionValue="WhsCode"
            optionLabel="WhsCode"
            class="w-full"
            filter
            showClear
          >
            <template #option="{option}">
              <div class="flex flex-col">
                <span>{{ option.WhsCode }}</span>
                <span class="text-xs">{{ option.WhsName }}</span>
              </div>
            </template>
          </Dropdown>
        </section>
<!--        <section class="flex flex-col justify-center">-->
<!--          <label>Medicamento</label>-->
<!--          <Dropdown-->
<!--            id="Md"-->
<!--            v-model="filtros.CodMx"-->
<!--            :options="medicamentos"-->
<!--            optionLabel="Nombre"-->
<!--            optionValue="Codigo"-->
<!--            class="w-full"-->
<!--            filter-->
<!--            showClear-->
<!--          />-->
<!--        </section>-->
      </div>
      <div class="flex justify-end">
        <Button
          id="GenerarReporte"
          label="Generar Reporte"
          icon="pi pi-search"
          class="p-button-raised p-button-rounded p-button-success"
          @click="clickGenerarReporte"
        />
      </div>
    </div>
  </Dialog>
</template>
<script setup>
import { ref, defineExpose } from 'vue'
import ReportesService from '../../services/reportes.service'
import BodegasService from '../../services/bodegas.service'

// Services
const reportesService = new ReportesService()
const bodegasService = new BodegasService()

// Refs
const visible = ref(false)
const filtros = ref({
  CodBod: null,
  CodMx: null
})
const bodegas = ref([])
// const medicamentos = ref([])

// Methods
const actionModal = () => {
  bodegasService.listarBodegasTodas().then((res) => {
    bodegas.value = res.data
  })
  visible.value = !visible.value
}
const clickGenerarReporte = () => {
  const url = reportesService.reporteStock(filtros.value)
  window.open(url, '_blank')
}

// Expose methods
defineExpose({
  actionModal
})
</script>
